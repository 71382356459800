[class*="component-card_"] {
  background-color: #191e3a;
}

/*
	Component Card 1
*/

.component-card_1 {
  border: 1px solid #1b2e4b;
  border-radius: 6px;
  width: 19rem;
  margin: 0 auto;
}
.component-card_1 .card-body {
  padding: 28px 25px;
}
.component-card_1 .icon-svg {
  margin-bottom: 20px;
  display: inline-block;
  background: #3b3f5c;
  padding: 12px;
  border-radius: 50%;
  color: #f1f2f3;
}
.component-card_1 svg {
  width: 20px;
  height: 20px;
}
.component-card_1 .card-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.component-card_1 .card-text {
  color: #888ea8;
}

/*
	Component Card 2
*/

.component-card_2 {
  border: 1px solid #1b2e4b;
  border-radius: 6px;
  width: 18rem;
  margin: 0 auto;
}
.component-card_2 .card-body {
  padding: 22px 20px;
}
.component-card_2 .card-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.component-card_2 .card-text {
  color: #888ea8;
  line-height: 22px;
}
.component-card_2 a.btn-primary {
  margin: 26px 0 0 0;
  padding: 10px 16px;
  background: #f8538d !important;
  border-color: #f8538d;
}

/*
	Component Card 3
*/

.component-card_3 {
  border: none;
  border-radius: 8px;
  background: #3b3f5c;
  width: 18rem;
  margin: 0 auto;
}
.component-card_3 .card-body {
  padding: 22px 20px;
  text-align: center;
}
.component-card_3 .card-body img {
  width: 85px;
  height: 85px;
  margin-bottom: 25px;
  border-radius: 50%;
}
.component-card_3 .card-body h5.card-user_name {
  font-size: 15px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
}
.component-card_3 .card-body p.card-user_occupation {
  font-size: 14px;
  color: #e3e4eb;
  letter-spacing: 1px;
}
.component-card_3 .card-body .card-star_rating {
  margin-bottom: 24px;
}
.component-card_3 .card-body .card-star_rating svg {
  width: 20px;
  color: #e2a03f;
}
.component-card_3 .card-body .card-star_rating svg.fill {
  fill: #e2a03f;
}
.component-card_3 .card-body .card-text {
  color: #e3e4eb;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 1px;
}

/*
	Component Card 4
*/

.component-card_4 {
  width: 100%;
  margin: 0;
  border: none;
  border: 1px solid #1b2e4b;
  border-radius: 8px;
}
.component-card_4 .card-body {
  padding: 0;
  display: flex;
}
.component-card_4 .user-profile {
  align-self: center;
  padding: 10px 25px;
}
.component-card_4 img {
  border-radius: 50%;
}

.component-card_4 .user-info {
  padding: 15px 8px 24px 0px;
}
.component-card_4 .card-user_name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.component-card_4 .card-user_occupation {
  color: #888ea8;
  font-size: 13px;
}
.component-card_4 .card-star_rating span {
  display: inline-block;
  padding: 0px 8px;
  font-size: 15px;
  border-radius: 50px;
  margin-bottom: 22px;
}
.component-card_4 .card-star_rating svg {
  width: 16px;
  vertical-align: bottom;
}
.component-card_4 .card-text {
  color: #bfc9d4;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 23px;
}

/*
	Component Card 5
*/

.component-card_5 {
  width: 20rem;
  margin: 0 auto;
  border: none;
  border-radius: 8px;
  background: rgba(92, 26, 195, 0.26);
}
.component-card_5 .card-body {
  padding: 30px 30px;
}
.component-card_5 .card-body .user-info {
  display: flex;
  padding: 22px 0 0 0;
}
.component-card_5 .card-body .media-body {
  align-self: center;
}
.component-card_5 .card-body img {
  width: 56px;
  height: 56px;
  margin-right: 18px;
  border-radius: 50%;
}
.component-card_5 .card-body h5.card-user_name {
  font-size: 15px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 3px;
}
.component-card_5 .card-body p.card-user_occupation {
  font-size: 14px;
  color: #e3e4eb;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.component-card_5 .card-body .card-text {
  color: #f1f2f3;
  font-size: 14px;
  letter-spacing: 1px;
}

/*
	Component Card 6
*/

.component-card_6 {
  border-radius: 8px;
  border: 1px solid #1b2e4b;
  width: 19rem;
  margin: 0 auto;
}
.component-card_6 .card-body {
  padding: 30px 30px;
}
.component-card_6 .card-body .user-info {
  display: flex;
  padding: 13px 0 0 0;
}
.component-card_6 .card-body .media-body {
  align-self: center;
}
.component-card_6 .card-body img {
  width: 56px;
  height: 56px;
  margin-right: 18px;
  border-radius: 50%;
}
.component-card_6 .card-body h5.card-user_name {
  font-size: 16px;
  color: #bfc9d4;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 3px;
}
.component-card_6 .card-body p.card-user_occupation {
  font-size: 14px;
  color: #888ea8;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.component-card_6 .card-body .card-text {
  color: #888ea8;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
.component-card_6 .card-body .card-star_rating svg {
  width: 12px;
  color: #e2a03f;
}
.component-card_6 .card-body .card-star_rating svg.fill {
  fill: #e2a03f;
}

/*
	Component Card 7
*/

.component-card_7 {
  background-color: rgba(27, 85, 226, 0.3803921568627451);
  color: #fff;
  border: none;
  width: 18rem;
  margin: 0 auto;
}
.component-card_7 .card-body {
  padding: 23px 10px;
  text-align: center;
}
.component-card_7 .card-text {
  color: #fff;
  margin-bottom: 20px;
}
.component-card_7 .rating-count {
  color: #fff;
  margin-bottom: 20px;
}
.component-card_7 .rating-stars svg {
  width: 18px;
  color: #e2a03f;
}
.component-card_7 .rating-stars svg.fill {
  fill: #e2a03f;
}

/*
	Component Card 8
*/

.component-card_8 {
  border: 1px solid #1b2e4b;
  width: 24rem;
  margin: 0 auto;
}
.component-card_8 .progress-order .progress-order-header h6 {
  color: #bfc9d4;
  font-weight: 600;
  font-size: 16px;
}
.component-card_8 .progress-order .progress-order-header span.badge {
  font-size: 12px;
  padding: 6px 10px;
  color: #eaf1ff;
  background-color: #4361ee;
}
.component-card_8 .progress-order .progress-order-body ul li img {
  width: 40px;
  height: 40px;
}
.component-card_8 .progress-order .progress-order-body ul li.badge-notify {
  position: relative;
}
.component-card_8
  .progress-order
  .progress-order-body
  ul
  li.badge-notify
  .notification {
  position: absolute;
  top: -15px;
  right: -34px;
}
.component-card_8
  .progress-order
  .progress-order-body
  ul
  li.badge-notify
  .notification
  span.badge {
  background-color: #0e1726;
  color: #e0e6ed;
  font-size: 12px;
  padding: 4px 7px;
  font-weight: 500;
}
.component-card_8 .progress-order .progress-order-body .p-o-percentage {
  font-size: 14px;
  color: #e0e6ed;
}
.component-card_8 .progress-order .progress-order-body .progress {
  height: 6px;
  border-radius: 30px;
  background-color: #0e1726;
}
.component-card_8 .progress-order .progress-order-body .progress .p-o-progress {
  border-radius: 30px;
}
.pending-order {
  padding: 22px 20px;
}

/*
	Component Card 9
*/

.component-card_9 {
  border: 1px solid #1b2e4b;
  border-radius: 6px;
  width: 22rem;
  margin: 0 auto;
}
.component-card_9 .card-body {
  padding: 22px 20px;
}
.component-card_9 .meta-date {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #009688;
}
.component-card_9 .card-title {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.component-card_9 .card-text {
  color: #888ea8;
  line-height: 22px;
}
.component-card_9 .meta-info {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 15px;
}
.component-card_9 .meta-info:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 215px;
  background: #1b2e4b;
  top: 0;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}
.component-card_9 .meta-user {
  display: flex;
}
.component-card_9 .avatar {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  font-size: 1rem;
  margin-right: 10px;
}
.component-card_9 .avatar .avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #515365;
  color: #e0e6ed;
  font-size: 14px;
}
.component-card_9 .user-name {
  align-self: center;
  color: #25d5e4;
  font-weight: 600;
}
.component-card_9 .meta-action {
  display: flex;
}
.component-card_9 .meta-likes {
  align-self: center;
  margin-right: 13px;
  color: #009688;
}
.component-card_9 .meta-likes svg {
  width: 16px;
  vertical-align: sub;
  color: #009688;
  fill: rgba(27, 85, 226, 0.23921568627450981);
}
.component-card_9 .meta-view {
  align-self: center;
  color: #009688;
}
.component-card_9 .meta-view svg {
  width: 16px;
  vertical-align: sub;
  color: #009688;
  fill: rgba(27, 85, 226, 0.23921568627450981);
}

/*
	Media Queries
*/

@media (max-width: 575px) {
  /*
		Component Card 1
	*/
  .component-card_1 {
    width: auto;
  }

  /*
		Component Card 2
	*/
  .component-card_2 {
    width: auto;
  }

  /*
		Component Card 3
	*/
  .component-card_3 {
    width: auto;
  }

  /*
		Component Card 4
	*/
  .component-card_4 {
    width: auto;
    text-align: center;
  }
  .component-card_4 .card-body {
    padding: 0;
    display: block;
  }
  .component-card_4 .user-info {
    padding: 0 25px 25px 25px;
  }
  .component-card_4 .user-profile {
    align-self: center;
    padding: 25px 25px;
  }

  /*
		Component Card 5
	*/
  .component-card_5 {
    width: auto;
  }

  /*
		Component Card 6
	*/
  .component-card_6 {
    width: auto;
  }

  /*
		Component Card 7
	*/
  .component-card_7 {
    width: auto;
  }

  /*
		Component Card 8
	*/
  .component-card_8 {
    width: auto;
  }

  /*
		Component Card 9
	*/
  .component-card_9 {
    width: auto;
  }
  .component-card_9 .meta-info {
    display: block;
  }
  .component-card_9 .meta-action {
    margin-top: 16px;
  }
  .component-card_9 .meta-info:before {
    width: 50%;
  }
}
