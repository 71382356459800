* ::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #141b2d;
}

::-webkit-scrollbar-thumb {
  background: #141b2d;
}

html {
  min-height: 100%;
}
body {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #141b2d;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: "Nunito", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e0e6ed;
}
:focus {
  outline: none;
}
::-moz-selection {
  /* Code for Firefox */
  color: #4361ee;
  background: transparent;
}
::selection {
  color: #4361ee;
  background: transparent;
}
p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  color: #ebedf2;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #191e3a;
}
strong {
  font-weight: 600;
}
code {
  color: #e7515a;
}

/*Page title*/

.page-header {
  border: 0;
  margin: 0;
}
.page-header:before,
.page-header:after {
  display: table;
  content: "";
  line-height: 0;
}
.page-header:after {
  clear: both;
}
.page-title h3 {
  margin: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: #bfc9d4;
  font-weight: 700;
}
.page-title span {
  display: block;
  font-size: 11px;
  color: #888;
  font-weight: normal;
}
.main-container {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 0 0 16px;
  background-color: #141b2d !important;
}
#container.fixed-header {
  margin-top: 56px;
}
#content {
  position: relative;
  width: 50%;
  flex-grow: 8;
  margin-top: 106px;
  margin-bottom: 0;
  margin-left: 212px;
  transition: 0.6s;
}
.main-container-fluid > .main-content > .container {
  float: left;
  width: 100%;
}
#content > .wrapper {
  -webkit-transition: margin ease-in-out 0.1s;
  -moz-transition: margin ease-in-out 0.1s;
  -o-transition: margin ease-in-out 0.1s;
  transition: margin ease-in-out 0.1s;
  position: relative;
}

.widget {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.layout-top-spacing {
  margin-top: 20px;
}
.layout-spacing {
  padding-bottom: 40px;
}
.layout-px-spacing {
  padding: 0 20px 0 18px !important;
  min-height: calc(100vh - 170px) !important;
}
.sidebar-closed > .sidebar-wrapper {
  width: 70px !important;
  padding-left: 0px !important;
}

.sidebar-closed .layout-px-spacing {
  padding: 0 16px !important;
}
.widget.box .widget-header {
  background: #0e1726;
  padding: 0px 8px 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.row [class*="col-"] .widget .widget-header h4 {
  color: #ebedf2;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  padding: 16px 15px;
}
.seperator-header {
  background: transparent;
  box-shadow: none;
  margin-bottom: 40px;
  border-radius: 0;
}
.seperator-header h4 {
  margin-bottom: 0;
  line-height: 1.4;
  padding: 5px 8px;
  font-size: 15px;
  border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;
  background: rgba(0, 150, 136, 0.26);
  color: #009688;
  font-weight: 500;
}
.widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}
.widget .widget-header:before,
.widget .widget-header:after {
  display: table;
  content: "";
  line-height: 0;
}
.widget .widget-header:after {
  clear: both;
}
.widget-content-area {
  padding: 20px;
  position: relative;
  background-color: #1f2940;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.content-area {
  max-width: 58.333333%;
  margin-left: 80px;
}

/* 
=====================
    Navigation Bar
=====================
*/

.header-container {
  z-index: 1030;
  border-bottom: 1px solid #1f2940;
}
.header-container .navbar {
  padding: 4px 0;
}
.navbar {
  padding: 0;
  background: #1f2940;
}
.navbar .theme-brand {
  padding: 0.9px 0 0.9px 27px !important;
  border-radius: 6px 6px 0 0;
  justify-content: space-between;
}
.navbar .theme-brand li.theme-logo {
  align-self: center;
}
.navbar .theme-brand li.theme-logo img {
  width: 34px;
  height: 34px;
}
.navbar .theme-brand li.theme-text {
  margin-right: 47px;
}
.navbar .theme-brand li.theme-text a {
  font-size: 24px !important;
  color: #e0e6ed !important;
  line-height: 2.75rem;
  padding: 0 0.8rem;
  text-transform: initial;
  position: unset;
  font-weight: 700;
}

.navbar.expand-header {
  margin: 0;
}
.navbar-brand {
  width: 5.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-right: 0rem;
}
.navbar-expand-sm .navbar-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-expand-sm .navbar-item .nav-link {
  line-height: 2.75rem;
  padding: 6.24px 9.6px;
  text-transform: initial;
  position: unset;
}
.header-container .navbar .dropdown-menu {
  border-radius: 6px;
  border-color: #e0e6ed;
}
.header-container .navbar .dropdown-item {
  line-height: 1.8;
  font-size: 0.96rem;
  padding: 15px 0 15px 0;
  word-wrap: normal;
}
.header-container .navbar .navbar-item .nav-item .dropdown-item.active,
.header-container .navbar .navbar-item .nav-item .dropdown-item:active {
  background-color: transparent;
  color: #16181b;
}
.header-container .navbar .navbar-item .nav-item.dropdown .nav-link:hover span {
  color: #e0e6ed !important;
}
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu {
  top: 139% !important;
  border-radius: 0;
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  background: #1b2e4b;
  padding: 10px;
}
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu.show {
  top: 107% !important;
}
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after {
  position: absolute;
  content: "";
  top: -10px;
  margin-left: -7px;
  height: 0;
  width: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 15px solid #fff;
  left: auto;
  right: 7px;
  border-bottom-color: #1b2e4b;
}

/*   Language   */

.header-container .navbar .language-dropdown {
  margin-left: 20px;
}
.header-container .navbar .language-dropdown a.nav-link {
  position: relative;
  font-size: 24px;
  padding: 0;
}
.header-container .navbar .language-dropdown a.dropdown-toggle:after {
  display: none;
}
.header-container .navbar .language-dropdown a.dropdown-toggle img {
  width: 20px;
  height: 20px;
  margin: 11px 0px;
}

/*   Language Dropdown  */
.header-container
  .navbar
  .navbar-item
  .nav-item.dropdown.language-dropdown
  .dropdown-menu {
  min-width: 9rem;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  box-shadow: none;
  top: 139% !important;
  z-index: 1070;
  padding: 6px 6px;
  border-radius: 4px;
}
.header-container
  .navbar
  .navbar-item
  .nav-item.dropdown.language-dropdown
  .dropdown-menu.show {
  top: 107% !important;
}
.header-container .navbar .language-dropdown .dropdown-menu:before {
  top: -10px;
  left: 41%;
  right: auto !important;
}
.header-container .navbar .language-dropdown .dropdown-menu:after {
  top: -8.5px;
  left: 49% !important;
  right: auto !important;
}
.header-container
  .navbar
  .language-dropdown
  .dropdown-menu
  .dropdown-item:hover {
  background: transparent;
}
.header-container
  .navbar
  .language-dropdown
  .dropdown-menu
  .dropdown-item.active,
.header-container
  .navbar
  .language-dropdown
  .dropdown-menu
  .dropdown-item:active {
  background: transparent;
  color: #16181b;
}
.header-container .navbar .language-dropdown .dropdown-menu a img {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.header-container .navbar .language-dropdown .dropdown-menu a span {
  color: #bfc9d4;
  font-weight: 500;
}
.header-container
  .navbar
  .language-dropdown
  .dropdown-menu
  a.dropdown-item:hover
  span {
  color: #fff;
}

/*Message Dropdown*/
.navbar .navbar-item .nav-item.dropdown.message-dropdown {
  margin-left: 20px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link {
  padding: 0 0;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link:after {
  display: none;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link svg {
  color: #888ea8;
  width: 22px;
  height: 22px;
  stroke-width: 1.6;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link span.badge {
  position: absolute;
  top: 13px;
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 1px 5px 0px;
  font-size: 10px;
  color: #fff !important;
  background: #4361ee;
  border: 1px solid #e0e6ed;
  top: 10px;
  right: -8px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown.double-digit
  .nav-link
  span.badge {
  top: 11px;
  right: 1px;
  width: 22px;
  height: 22px;
  padding: 3px 3px 0px;
  font-size: 9px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
  min-width: 13rem;
  right: -15px;
  left: auto;
  padding: 10px !important;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu.show {
  top: 107% !important;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 8px 8px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item.active,
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:active {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:focus,
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:hover {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:first-child {
  padding-top: 8px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after {
  right: 17px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:last-child {
  padding-bottom: 8px;
  cursor: pointer;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .avatar {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 39px;
  font-size: 14px;
  margin-right: 11px;
  font-weight: 500;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .avatar
  .avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #304aca;
  color: #fff;
  font-weight: 600;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 3px solid rgba(224, 230, 237, 0.16);
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body
  h5.usr-name {
  font-size: 15px;
  margin-bottom: 0px;
  color: #bfc9d4;
  font-weight: 500;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:hover
  .media-body
  h5.usr-name {
  color: #fff;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body {
  align-self: center;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body
  p.msg-title {
  font-size: 10px;
  font-weight: 700;
  color: #888ea8;
  margin-bottom: 0;
  letter-spacing: 0;
}

/*Notification Dropdown*/

.navbar .navbar-item .nav-item.dropdown.notification-dropdown {
  margin-left: 16px;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link {
  padding: 0 0;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link:after {
  display: none;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
  color: #888ea8;
  width: 22px;
  height: 22px;
  stroke-width: 1.6;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .nav-link
  span.badge {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  color: #fff !important;
  background: #1abc9c;
  top: 7px;
  right: 2px;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
  min-width: 15rem;
  right: -7px;
  left: auto;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:hover
  svg {
  color: #25d5e4;
  fill: rgba(27, 85, 226, 0.23921568627450981);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:focus,
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:hover {
  background-color: transparent;
  cursor: pointer;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgba(59, 63, 92, 0.25);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item
  .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item
  svg {
  width: 25px;
  height: 30px;
  color: #888ea8;
  margin-right: 12px;
  fill: rgba(0, 23, 55, 0.08);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item
  .notification-para {
  color: #506690;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  white-space: normal;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item
  .notification-para
  .user-name {
  color: #e0e6ed;
  font-weight: 700;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item
  .notification-meta-time {
  font-size: 13px;
  font-weight: 600;
  color: #bfc9d4;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
  min-width: 15rem;
  right: -6px;
  left: auto;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu.show {
  top: 107% !important;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 0.625rem 1rem;
  cursor: pointer;
  border-radius: 0;
  background: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  svg {
  width: 23px;
  height: 23px;
  font-weight: 600;
  color: #e2a03f;
  fill: rgba(226, 160, 63, 0.27058823529411763);
  margin-right: 9px;
  align-self: center;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media.file-upload
  svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.23921568627450981);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media.server-log
  svg {
  color: #009688;
  fill: rgba(0, 150, 136, 0.3686274509803922);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media-body {
  display: flex;
  justify-content: space-between;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info {
  display: inline-block;
  white-space: normal;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info
  h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  color: #bfc9d4;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:hover
  .data-info
  h6 {
  color: #fff;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info
  p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #888ea8;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status {
  display: inline-block;
  white-space: normal;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-x {
  color: #bfc9d4;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-x:hover {
  color: #e7515a;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-check {
  color: #fff;
  background: #0d9a5d;
  border-radius: 50%;
  padding: 3px;
  width: 22px;
  height: 22px;
}
.navbar .navbar-item .nav-item.search-animated {
  position: relative;
}
.navbar .navbar-item .nav-item.search-animated svg {
  font-weight: 600;
  margin: 0 9.6px;
  cursor: pointer;
  color: #888ea8;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control {
  width: 100%;
  font-size: 14px;
  background-color: #0e1726;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;
  padding: 0px 4px 0px 35px;
  height: 36px;
  font-weight: 600;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus {
  border: 1px solid #3b3f5c;
  box-shadow: none;
}
.navbar .navbar-item .nav-item .form-inline.search .search-form-control {
  border: 1px solid rgba(81, 83, 101, 0.28);
  width: 100%;
  width: 370px;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-webkit-input-placeholder {
  color: #888ea8;
  letter-spacing: 1px;
  font-size: 13px;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-ms-input-placeholder {
  color: #888ea8;
  letter-spacing: 1px;
  font-size: 13px;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-moz-placeholder {
  color: #888ea8;
  letter-spacing: 1px;
  font-size: 13px;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-webkit-input-placeholder {
  color: #3b3f5c;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-ms-input-placeholder {
  color: #3b3f5c;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-moz-placeholder {
  color: #3b3f5c;
}
.search-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent !important;
  z-index: 814 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.search-overlay.show {
  display: block;
  opacity: 0.1;
}

/* User Profile Dropdown*/
.navbar .navbar-item .nav-item.user-profile-dropdown {
  margin: 0 20px 0 16px;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link.user {
  padding: 0;
  position: relative;
}
.navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
  display: none;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link img {
  width: 28px;
  border-radius: 4px;
  height: 28px;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
  max-width: 13rem;
  padding: 0 11px;
}

.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 0;
  background: transparent;
}

.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  a {
  display: block;
  color: #bfc9d4;
  font-size: 13px;
  font-weight: 500;
  padding: 9px 14px;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item:hover
  a {
  color: #fff;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item.active,
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item:active {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  svg {
  width: 17px;
  margin-right: 7px;
  height: 17px;
}

/* 
===============
    Sidebar
===============
*/

.sub-header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1029;
  display: flex;
}
.sub-header-container .navbar {
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  background: rgb(26, 28, 45);
  border-radius: 0;
  padding: 9px 0;
  justify-content: flex-start;
  min-height: 53px;
  width: 100%;
}
.sub-header-container .navbar .toggle-sidebar,
.sub-header-container .navbar .sidebarCollapse {
  position: relative;
  padding: 0 25px 0 31px;
  margin-left: 0;
  cursor: pointer !important;
}
.sub-header-container .navbar .sidebarCollapse svg {
  color: #e0e6ed;
  vertical-align: text-top;
  stroke-width: 1.6;
}
.page-header {
  display: flex;
  padding: 0 0;
}
.sub-header-container .breadcrumb-one {
  display: inline-block;
}
.sub-header-container .breadcrumb-one .breadcrumb {
  padding: 0;
  vertical-align: sub;
  background-color: transparent;
  margin-bottom: 0;
}
.sub-header-container .breadcrumb-one .breadcrumb-item {
  align-self: center;
  display: flex;
}
.sub-header-container .breadcrumb-one .breadcrumb-item a {
  color: #e0e6ed;
  vertical-align: sub;
}
.sub-header-container .breadcrumb-one .breadcrumb-item a svg {
  width: 18px;
  height: 18px;
  vertical-align: sub;
  fill: #e0e6ed;
}
.sub-header-container .breadcrumb-one .breadcrumb-item.active a {
  color: #515365;
}
.sub-header-container .breadcrumb-one .breadcrumb-item span {
  vertical-align: sub;
}
.sub-header-container .breadcrumb-one .breadcrumb-item.active {
  color: #fff;
  font-weight: 600;
}
.sub-header-container .breadcrumb-one .breadcrumb-item + .breadcrumb-item {
  padding: 0px;
}
.sub-header-container
  .breadcrumb-one
  .breadcrumb-item
  + .breadcrumb-item::before {
  color: #e0e6ed;
  padding: 0 6px 0 6px;
  vertical-align: bottom;
  display: inline-block;
  float: none;
}
@media (max-width: 575px) {
  .page-header {
    display: block;
  }
}
.sub-header-container .more-dropdown .custom-dropdown-icon {
  padding-right: 20px;
}
.sidebar-wrapper {
  width: 206px;
  width: 212px;
  position: fixed;
  z-index: 1028;
  border-radius: 6px 6px 0 0;
  transition: 0.6s;
  height: 100vh;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 55px;
  left: 7px;
}
.sub-header-container .more-dropdown .custom-dropdown-icon a.dropdown-toggle {
  position: relative;
  padding: 9px 35px 10px 15px;
  border: 1px solid #3b3f5c;
  border-radius: 8px;
  transform: none;
  font-size: 13px;
  line-height: 17px;
  background-color: rgb(26, 28, 45);
  letter-spacing: normal;
  min-width: 115px;
  text-align: inherit;
  color: #e0e6ed;
  box-shadow: none;
  max-height: 35px;
}

.sub-header-container
  .more-dropdown
  .custom-dropdown-icon
  a.dropdown-toggle
  svg {
  position: absolute;
  right: 15px;
  top: 11px;
  color: #e0e6ed;
  width: 13px;
  height: 13px;
  margin: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.sub-header-container
  .more-dropdown
  .custom-dropdown-icon.show
  a.dropdown-toggle
  svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu {
  position: absolute;
  right: 16px;
  top: 40px !important;
  padding: 8px 0;
  border: none;
  min-width: 155px;
  background-color: #191e3a;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu a {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  color: #e0e6ed;
}
.sub-header-container
  .more-dropdown
  .custom-dropdown-icon
  .dropdown-menu
  a:hover {
  background-color: rgba(59, 63, 92, 0.45);
  color: #e0e6ed;
}

/* 
===============
    Sidebar
===============
*/
.sidebar-wrapper {
  width: 206px;
  width: 212px;
  position: fixed;
  z-index: 1028;
  border-radius: 6px 6px 0 0;
  transition: 0.6s;
  height: 100vh;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 106px;
  left: 16px;
}
.sidebar-theme {
  background-color: #141b2d;
  background: transparent !important;
}

.sidebar-closed > #content {
  margin-left: 0;
}
.sidebar-closed .layout-px-spacing {
  padding: 0 30px !important;
}
#sidebar .navbar-brand .img-fluid {
  display: inline;
  width: 44px;
  height: auto;
  margin-left: 20px;
  margin-top: 5px;
}
.sidebar-closed > .sidebar-wrapper {
  width: 70px !important;
  padding-left: 0px !important;
}
#sidebar .border-underline {
  border-left: 1px solid #ccc;
  height: 20px;
  margin-top: 18px;
  margin-left: 0px;
  margin-right: 8px;
}
.sidebar-closed > #content {
  margin-left: 72px !important;
}

#sidebar * {
  overflow: hidden;
  white-space: nowrap;
}
.shadow-bottom {
  display: block;
  position: absolute;
  z-index: 2;
  height: 33px;
  width: 101.6%;
  pointer-events: none;
  margin-top: -13px;
  left: -4px;
  -webkit-filter: blur(5px);
  filter: blur(3px);
  background: -webkit-linear-gradient(
    top,
    #1a233af0 44%,
    #1a233abf 73%,
    #2c303c00
  );
  background: linear-gradient(180deg, #060818 49%, #060818eb 85%, #2c303c00);
}
#sidebar ul.menu-categories {
  position: relative;
  width: 100%;
  height: calc(100vh - 127px) !important;
  overflow: hidden;
}
#sidebar ul.menu-categories.ps {
  height: calc(100vh - 107px) !important;
  margin-right: 0;
  padding-right: 22px;
  border-right: 1px solid #24262c;
}
#sidebar .ps__thumb-y {
  right: 4px;
  background-color: #515365;
}
#sidebar .ps__rail-y:hover > .ps__thumb-y {
  background-color: #3b3f5c;
}
#sidebar
  ul.menu-categories
  li
  > .dropdown-toggle[data-active="true"]
  svg.feather-chevron-right {
  transform: rotate(90deg);
}
#sidebar
  ul.menu-categories
  li
  > .dropdown-toggle[aria-expanded="true"]
  svg.feather-chevron-right {
  transform: rotate(90deg);
}
#sidebar ul.menu-categories li.menu:first-child ul.submenu > li a {
  justify-content: flex-start;
}
#sidebar ul.menu-categories li.menu:first-child ul.submenu > li a i {
  align-self: center;
  margin-right: 12px;
  font-size: 19px;
  width: 21px;
}
#sidebar ul.menu-categories li.menu:first-child > .dropdown-toggle {
  margin-top: 21px;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  color: #e7ebf3;
  padding: 11px;
  transition: 0.6s;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 1px;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle > div {
  align-self: center;
}
#sidebar ul.menu-categories li.menu .dropdown-toggle:after {
  display: none;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle svg {
  color: #e7ebf3;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: rgba(0, 23, 55, 0.08);
  stroke-width: 1.6;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle[data-active="true"] {
  background: "linear-gradient(to right , #d532ee, #d990e4  )";
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #fafafa;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle[data-active="true"] svg {
  color: #e0e6ed;
  fill: none;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[data-active="true"]:hover
  svg {
  color: #e0e6ed;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[data-active="true"]
  span {
  color: #e0e6ed;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle:hover,
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle.dropdown-toggle:not([data-active="true"]):not(
    [aria-expanded="true"]
  ):hover {
  background: "linear-gradient(to right , #d532ee, #d990e4  )";
  color: #e0e6ed;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]:not([data-active="true"]) {
  background: #0e1726;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: #fafafa;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle:hover svg,
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]:not([data-active="true"])
  svg {
  color: #e0e6ed;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]:not([data-active="true"])
  svg {
  color: #fafafa;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle
  svg.feather-chevron-right,
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle
  svg.feather-chevron-right {
  vertical-align: middle;
  margin-right: 0;
  width: 15px;
}
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[data-active="true"]
  svg.flaticon-right-arrow,
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[data-active="true"]
  svg.flaticon-down-arrow {
  background-color: transparent;
}
#sidebar ul.menu-categories li.menu > a span:not(.badge) {
  vertical-align: middle;
}
#sidebar ul.menu-categories ul.submenu > li a {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 48px;
  padding-left: 24px;
  margin-left: 36px;
  font-size: 13px;
  color: #e0e6ed;
}
#sidebar ul.menu-categories li.menu ul.submenu > li a:before {
  content: "";
  background-color: #888ea8;
  position: absolute;
  height: 3px;
  width: 3px;
  top: 17.5px;
  left: 13px;
  border-radius: 50%;
}
#sidebar ul.menu-categories ul.submenu > li.active a {
  color: #61b6cd;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: 600;
}
#sidebar ul.menu-categories ul.submenu > li a.dropdown-toggle {
  padding: 10px 15px 10px 24px;
}
#sidebar ul.menu-categories li.menu ul.submenu > li.active a:before {
  background-color: #61b6cd;
}
#sidebar ul.menu-categories ul.submenu > li.active {
  position: relative;
}
#sidebar ul.menu-categories ul.submenu > li a:hover {
  color: #61b6cd;
}
#sidebar ul.menu-categories ul.submenu > li a:hover:before {
  background-color: #61b6cd !important;
}
#sidebar ul.menu-categories ul.submenu > li a i {
  align-self: center;
  font-size: 9px;
}
#sidebar ul.menu-categories ul.submenu li > [data-active="true"] i {
  color: #61b6cd;
}
#sidebar ul.menu-categories ul.submenu li > [data-active="true"]:before {
  background-color: #61b6cd !important;
}
#sidebar ul.menu-categories ul.submenu li > a[data-active="true"] {
  color: #61b6cd;
}
#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a {
  position: relative;
  padding: 10px 12px 10px 48px;
  padding-left: 13px;
  margin-left: 47px;
  font-size: 12px;
  color: #506690;
}
#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li.active a {
  color: #009688;
}
#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:hover {
  color: #009688;
}
#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:before {
  display: none;
}
#sidebar
  ul.menu-categories
  ul.submenu
  > li
  ul.sub-submenu
  > li.active
  a:before {
  background-color: #009688;
}
.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1035 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.e-animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.e-fadeInUp {
  -webkit-animation-name: e-fadeInUp;
  animation-name: e-fadeInUp;
}

#sidebar ul.menu-categories .ps__rail-y {
  right: -4px !important;
}

/*  
    ======================
        Footer-wrapper
    ======================
*/

.footer-wrapper {
  padding: 10px 20px 10px 18px;
  display: inline-block;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  border-top-left-radius: 6px;
  display: flex;
  justify-content: space-between;
}
.main-container.sidebar-closed .footer-wrapper {
  border-radius: 0;
}
.footer-wrapper .footer-section p {
  margin-bottom: 0;
  color: #888ea8;
  font-size: 13px;
  letter-spacing: 1px;
}
.footer-wrapper .footer-section p a {
  color: #888ea8;
}
.footer-wrapper .footer-section svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.4196078431372549);
  width: 15px;
  height: 15px;
  vertical-align: text-top;
}
.sidebar-closed > .sidebar-wrapper {
  width: 70px !important;
  padding-left: 0px !important;
}
/*  
    ======================
        MEDIA QUERIES
    ======================
*/

@media (min-width: 992px) {
  .sidebar-closed #sidebar .theme-brand li.theme-text a {
    display: none;
  }
}

@media (max-width: 991px) {
  .sidebar-wrapper {
    -webkit-backface-visibility: hidden !important;
    border-radius: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    position: fixed !important;
    top: 55px !important;
    -webkit-transform: translateZ(0) !important;
    width: 255px !important;
    z-index: 9999 !important;
  }
  .navbar .theme-brand {
    padding: 0.9px 0 0.9px 14px !important;
  }
  .sub-header-container .navbar .toggle-sidebar,
  .sub-header-container .navbar .sidebarCollapse {
    position: relative;
    padding: 0 25px 0 16px;
  }
  /* .main-container:not(.sbar-open) .sidebar-wrapper {
    width: 250px !important;
    left: 2px !important;
  } */
  .sidebar-closed > .sidebar-wrapper {
    padding-left: 2px !important;
  }
  .live {
    font-size: 20px !important;
  }
  /*
        =============
            NavBar
        =============
    */

  .main-container.sidebar-closed #content {
    margin-left: 0 !important;
  }
  /*
        =============
            Sidebar
        =============
    */

  #content {
    margin-left: 0;
  }
  #sidebar .theme-brand {
    border-radius: 0;
  }
  #sidebar .theme-brand {
    background-color: #4361ee;
    padding: 7px 12px 7px 24px;
    border-color: #4361ee;
  }
  .sidebar-closed #sidebar .theme-brand {
    padding: 0.9px 12px 0.9px 24px;
  }
  .main-container:not(.sbar-open) .sidebar-wrapper {
    width: 0 !important;
    left: -52px !important;
  }
  .sidebar-closed > .sidebar-wrapper {
    padding-left: 2px !important;
  }

  body.alt-menu .sidebar-closed > .sidebar-wrapper {
    width: 0;
    left: -52px;
  }
  .main-container {
    padding: 0;
  }
  #sidebar ul.menu-categories.ps {
    height: calc(100vh - 70px) !important;
  }
  .sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 255px;
    z-index: 9999;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    border-radius: 0;
  }
  .sidebar-closed > .sidebar-wrapper {
    left: 0;
  }
  .sidebar-noneoverflow {
    overflow: hidden;
  }
  #sidebar {
    height: 100vh !important;
    padding: 10px 0 10px 10px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    background: #060818;
    margin-top: 8px !important;
  }
  .footer-wrapper {
    padding: 6px 16px 9px 16px;
  }
  .layout-px-spacing {
    padding: 0 16px !important;
  }
  /* display .overlay when it has the .active class */

  .overlay.show {
    display: block;
    opacity: 0.7;
  }
}

@media (max-width: 767px) {
  .navbar .theme-brand li.theme-text {
    display: none;
    margin-right: 0;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    opacity: 0;
    transition: opacity 200ms, top 200ms;
  }
  .navbar
    .navbar-item
    .nav-item
    .form-inline.search.input-focused
    .search-form-control {
    opacity: 1;
    transition: opacity 200ms, top 200ms;
  }
  .navbar .navbar-item .nav-item .form-inline.search {
    opacity: 0;
    transition: opacity 200ms, top 200ms;
    top: -25px;
  }
  .navbar .navbar-item .nav-item .form-inline.search.input-focused {
    position: absolute;
    bottom: 0;
    top: 0;
    background: #060818;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 32;
    margin-top: 0px !important;
    display: flex;
    opacity: 1;
    transition: opacity 200ms, top 200ms;
  }
  .navbar .navbar-item .nav-item.search-animated {
    position: initial;
  }
  .navbar .navbar-item .nav-item.search-animated svg {
    font-weight: 600;
    margin: 0 9.6px;
    cursor: pointer;
    color: #e0e6ed;
    position: initial;
    width: 24px;
    height: 24px;
    transition: top 200ms;
    top: -25px;
  }
  .navbar .navbar-item .nav-item.search-animated.show-search svg {
    margin: 0;
    position: absolute;
    top: 16px;
    left: 16px;
    width: 22px;
    height: 22px;
    color: #e0e6ed;
    z-index: 40;
    transition: top 200ms;
  }
  .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-bar {
    width: 100%;
  }
  .navbar
    .navbar-item
    .nav-item
    .form-inline.search.input-focused
    .search-form-control {
    background: transparent;
    display: block;
    padding-left: 50px;
    padding-right: 12px;
    border: none;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    border: none;
    width: 100%;
    display: none;
  }
  .header-container .navbar .language-dropdown {
    margin-left: 16px;
  }
}
@media (max-width: 575px) {
  .navbar .navbar-item .nav-item.message-dropdown .dropdown-menu {
    min-width: 16.2rem;
  }
  .sub-header-container .breadcrumb-one .breadcrumb-item:not(.active) {
    display: none;
  }
  .sub-header-container
    .breadcrumb-one
    .breadcrumb-item
    + .breadcrumb-item::before {
    display: none;
  }
  .sub-header-container .breadcrumb-one .breadcrumb-item span {
    vertical-align: sub;
    font-size: 15px;
    font-weight: 700;
  }
  .sub-header-container .navbar .toggle-sidebar,
  .sub-header-container .navbar .sidebarCollapse {
    padding: 0 13px 0 16px;
  }
  .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after {
    right: 45px;
  }
  .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    right: -44px;
  }
  .navbar
    .navbar-item
    .nav-item.dropdown.notification-dropdown
    .dropdown-menu:after {
    right: 46px;
  }
  .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
    right: -82px;
  }
  .navbar
    .navbar-item
    .nav-item.dropdown.message-dropdown
    .dropdown-menu:after {
    right: 83px;
  }
  .footer-wrapper .footer-section.f-section-2 {
    display: none;
  }
}
.cursor-pointer {
  cursor: pointer;
}

#accordionExample {
  overflow-y: scroll !important;
}

#sidebar ul.menu-categories ul.submenu > li.active {
  color: #61b6cd;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: 600;
}
